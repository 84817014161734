import React from 'react';
import { connect } from 'react-redux';
import { ProductActions } from '../../redux/actions';
import { Button, Grid, FormControl, TextField, MenuItem, Chip, Paper, Tooltip } from '@material-ui/core';
import { getModalStyle, getModalClasses, CommonStyles } from '../../hooks/styles';
import Loading from '../../components/loading';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever } from '@material-ui/icons';

interface ProductFormProps {
  product: any
  serviceTypes: any[]
  addressTypes: any[]
  getProducts: () => void
  createProduct: (payload: any) => void
  updateProduct: (payload: any) => void
  closeModal: () => void
}

const ProductFormModal: React.FC<ProductFormProps> = (props) => {
  const classes = CommonStyles();
  const modalClasses = getModalClasses();
  const [modalStyle] = React.useState(getModalStyle());

  const getInitInputs = () => ({
    id: props.product?.id || '',
    name: props.product?.name || '',
    manufacturer: props.product?.manufacturer || '',
    description: props.product?.description || '',
    ndc: props.product?.ndc || '',
    minTemp: props.product?.minTemp || '',
    maxTemp: props.product?.maxTemp || '',
    defaultCarrier: props.product?.defaultCarrier || '',
    defaultServiceType: props.product?.defaultServiceType || '',
    serialized: props.product?.hasOwnProperty('serialized') ? props.product.serialized.toString() : '',
    rxItem: props.product?.hasOwnProperty('rxItem') ? props.product.rxItem.toString() : 'false',
    medguide: props.product?.hasOwnProperty('medguide') ? props.product.medguide.toString() : '',
    rxItemBagLabel: props.product?.hasOwnProperty('rxItemBagLabel') ? props.product.rxItemBagLabel.toString() : '',
    rxBagMax: props.product?.rxBagMax || '',
    rxPackMax: props.product?.rxPackMax || '',
    fillInstructions: props.product?.fillInstructions || '',
    storage: props.product?.storage || '',
    addressType: props.product?.addressType || '',
  })

  const [inputs, setInputs] = React.useState<any>(getInitInputs())
  const [formErrors, setFormErrors] = React.useState<any>({})

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [submitError, setSubmitError] = React.useState<string>('')

  const [selectedDimension, setSelectedDimension] = React.useState<any>({})
  const [dimensions, setDimensions] = React.useState<any[]>(props.product?.productDimensions ? JSON.parse(props.product.productDimensions || '[]') : [])

  const handleCancel = () => {
    props.closeModal();
  }

  const handleSetInputs = (key: string, value: any) => {
    setInputs({ ...inputs, [key]: value })
    setFormErrors({ ...formErrors, [key]: null })
    setSubmitError('')
  }

  const handleSetService = (value: any) => {
    const {carrier} = props.serviceTypes.find((serviceType: any) => serviceType.serviceType === value)
    setInputs({ ...inputs, defaultServiceType: value, defaultCarrier: carrier})
    setFormErrors({ ...formErrors, defaultServiceType: null, defaultCarrier: null })
    setSubmitError('')
  }

  const getRequiredInputs = () => {
    if (inputs.rxItem === 'true') {
      return ['id', 'name', 'manufacturer', 'description', 'ndc', 'minTemp', 'maxTemp', 'defaultServiceType', 'serialized', 'rxItem', 'medguide', 'rxItemBagLabel', 'rxBagMax', 'rxPackMax', 'fillInstructions']
    }
    return ['id', 'name', 'manufacturer', 'description', 'rxItem']
  }

  const formatString = (str: string) => {
    let formatted = str[0].toUpperCase() + str.slice(1);
    return formatted.replace(/([A-Z])/g, ' $1').trim()
  }

  const initEditUom = (dimension: any) => {
    setSelectedDimension(dimension)
  }

  const removeUom = (index: number) => {
    let newDimensions = dimensions.map((dimension: any) => dimension)
    newDimensions.splice(index, 1)
    setDimensions(newDimensions)
  }

  const handleSave = () => {
    const errors: any = {}
    for (let [key, value] of Object.entries(inputs)) {
      if (getRequiredInputs().includes(key) && value === '') {
        errors[key] = `${formatString(key)} is required`
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors)
      return
    }

    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      props.getProducts()
      props.closeModal()
    }

    const onError = () => {
      setIsLoading(false)
      setSubmitError('Failed to save product')
    }

    const requestBody = props.product ? getEditRequestBody() : getAddRequestBody()

    delete requestBody.updatedAt
    delete requestBody.updatedBy
    delete requestBody.cprDrugPk
    delete requestBody.packSets

    const payload = {
      requestBody: requestBody,
      success: onSuccess,
      error: onError
    }

    const submitFn = props.product ? props.updateProduct : props.createProduct

    submitFn(payload)
  }

  const getAddRequestBody = () => ({
    id: inputs.id,
    ndc: inputs.ndc,
    minTemp: +inputs.minTemp,
    maxTemp: +inputs.maxTemp,
    defaultCarrier: inputs.defaultCarrier,
    name: inputs.name,
    manufacturer: inputs.manufacturer,
    description: inputs.description,
    defaultServiceType: inputs.defaultServiceType,
    rxItemBagLabel: inputs.rxItemBagLabel === 'true' ? true : false,
    rxBagMax: +inputs.rxBagMax,
    rxPackMax: +inputs.rxPackMax,
    fillInstructions: inputs.fillInstructions,
    serialized: inputs.serialized === 'true' ? true : false,
    rxItem: inputs.rxItem === 'true' ? true : false,
    medguide: inputs.medguide === 'true' ? true : false,
    storage: inputs.storage,
    active: true,
    productDimensions: dimensions.length > 0 ? dimensions.map((dim: any) => {
      return {
        ...dim,
        uomQuantity: +dim.uomQuantity,
        height: +dim.height,
        width: +dim.width,
        length: +dim.length,
        weight: +dim.weight,
      }
    }) : [],
      ...(inputs.rxItem === 'true' && {addressType: inputs.addressType}),
  })

  const getEditRequestBody = () => ({
    ...props.product,
    ...{
      name: inputs.name,
      manufacturer: inputs.manufacturer,
      description: inputs.description,
      ndc: inputs.ndc,
      minTemp: +inputs.minTemp,
      maxTemp: +inputs.maxTemp,
      defaultCarrier: inputs.defaultCarrier,
      defaultServiceType: inputs.defaultServiceType,
      serialized: inputs.serialized === 'true' ? true : false,
      rxItem: inputs.rxItem === 'true' ? true : false,
      medguide: inputs.medguide === 'true' ? true : false,
      rxItemBagLabel: inputs.rxItemBagLabel === 'true' ? true : false,
      rxBagMax: +inputs.rxBagMax,
      rxPackMax: +inputs.rxPackMax,
      fillInstructions: inputs.fillInstructions,
      productDimensions: dimensions.length > 0 ? dimensions.map((dim: any) => {
        return {
          ...dim,
          uomQuantity: +dim.uomQuantity,
          height: +dim.height,
          width: +dim.width,
          length: +dim.length,
          weight: +dim.weight,
        }
      }) : [],
      storage: inputs.storage,
      active: true,
      ...(inputs.rxItem === 'true' && {addressType: inputs.addressType}),
    }
  })

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <div>
        <h3 id="simple-modal-title">{props.product ? 'Edit Product' : 'Add New Product'}</h3>
        <Grid container>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                label="ID"
                variant="outlined"
                value={inputs.id}
                error={formErrors.id ? true : false}
                helperText={formErrors.id ? formErrors.id : ''}
                onChange={(event) => { handleSetInputs('id', event.target.value) }}
                disabled={props.product ? true : false}
                required={props.product ? false : true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label="Name"
                variant="outlined"
                value={inputs.name}
                error={formErrors.name ? true : false}
                helperText={formErrors.name ? formErrors.name : ''}
                onChange={(event) => { handleSetInputs('name', event.target.value) }}
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                select
                variant="outlined"
                label={'Rx Item'}
                value={inputs.rxItem}
                error={formErrors.rxItem ? true : false}
                helperText={formErrors.rxItem || ''}
                onChange={(e: any) => {
                  handleSetInputs('rxItem', e.target.value)
                }}
                required
              >
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </TextField>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label="Manufacturer"
                variant="outlined"
                value={inputs.manufacturer}
                error={formErrors.manufacturer ? true : false}
                helperText={formErrors.manufacturer ? formErrors.manufacturer : ''}
                onChange={(event) => { handleSetInputs('manufacturer', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label="NDC"
                variant="outlined"
                value={inputs.ndc}
                error={formErrors.ndc ? true : false}
                helperText={formErrors.ndc ? formErrors.ndc : ''}
                onChange={(event) => { handleSetInputs('ndc', event.target.value) }}
                required={inputs.rxItem === 'true' ? true : false}
              />
            </FormControl>
            {inputs.rxItem === 'true' && <FormControl className={classes.formControl}>
              <TextField
                select
                variant="outlined"
                label={'Address Type'}
                value={inputs.addressType}
                onChange={(e: any) => {
                  handleSetInputs('addressType', e.target.value)
                }}
              >
                <MenuItem value={''}>Choose</MenuItem>
                {props.addressTypes.map((addressType: any) => <MenuItem value={addressType.addressType}>{addressType.addressType}</MenuItem>)}
              </TextField>
            </FormControl>}
          </Grid>
          {inputs.rxItem === 'true' && <>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  variant="outlined"
                  label={'Serialized'}
                  value={inputs.serialized}
                  error={formErrors.serialized ? true : false}
                  helperText={formErrors.serialized || ''}
                  onChange={(e: any) => {
                    handleSetInputs('serialized', e.target.value)
                  }}
                  required
                >
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  variant="outlined"
                  label={'Bag Label'}
                  value={inputs.rxItemBagLabel}
                  error={formErrors.rxItemBagLabel ? true : false}
                  helperText={formErrors.rxItemBagLabel || ''}
                  onChange={(e: any) => {
                    handleSetInputs('rxItemBagLabel', e.target.value)
                  }}
                  required
                >
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  variant="outlined"
                  label={'Med Guide'}
                  value={inputs.medguide}
                  error={formErrors.medguide ? true : false}
                  helperText={formErrors.medguide || ''}
                  onChange={(e: any) => {
                    handleSetInputs('medguide', e.target.value)
                  }}
                  required
                >
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  variant="outlined"
                  label={'Default Service'}
                  value={inputs.defaultServiceType}
                  error={formErrors.defaultServiceType ? true : false}
                  helperText={formErrors.defaultServiceType || ''}
                  onChange={(e: any) => {
                    handleSetService(e.target.value)
                  }}
                  required
                >
                  {props.serviceTypes.map((serviceType: any) => <MenuItem value={serviceType.serviceType}>{serviceType.serviceType}</MenuItem>)}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Min Temp"
                  variant="outlined"
                  type="number"
                  value={inputs.minTemp}
                  error={formErrors.minTemp ? true : false}
                  helperText={formErrors.minTemp ? formErrors.minTemp : ''}
                  onChange={(event) => { handleSetInputs('minTemp', event.target.value) }}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Max Temp"
                  variant="outlined"
                  type="number"
                  value={inputs.maxTemp}
                  error={formErrors.maxTemp ? true : false}
                  helperText={formErrors.maxTemp ? formErrors.maxTemp : ''}
                  onChange={(event) => { handleSetInputs('maxTemp', event.target.value) }}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Bag Max"
                  variant="outlined"
                  type="number"
                  value={inputs.rxBagMax}
                  error={formErrors.rxBagMax ? true : false}
                  helperText={formErrors.rxBagMax ? formErrors.rxBagMax : ''}
                  onChange={(event) => { handleSetInputs('rxBagMax', event.target.value) }}
                  required
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Pack Max"
                  variant="outlined"
                  type="number"
                  value={inputs.rxPackMax}
                  error={formErrors.rxPackMax ? true : false}
                  helperText={formErrors.rxPackMax ? formErrors.rxPackMax : ''}
                  onChange={(event) => { handleSetInputs('rxPackMax', event.target.value) }}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Storage"
                  variant="outlined"
                  value={inputs.storage}
                  error={formErrors.storage ? true : false}
                  helperText={formErrors.storage ? formErrors.minTemp : ''}
                  onChange={(event) => { handleSetInputs('storage', event.target.value) }}
                />
              </FormControl>
            </Grid>
          </>}
          <Grid item xs={12}>
            <FormControl className={classes.formControlLarge}>
              <TextField
                label="Description"
                variant="outlined"
                value={inputs.description}
                error={formErrors.description ? true : false}
                helperText={formErrors.description ? formErrors.description : ''}
                onChange={(event) => { handleSetInputs('description', event.target.value) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlLarge}>
              <TextField
                label="Fill Instructions"
                variant="outlined"
                value={inputs.fillInstructions}
                error={formErrors.fillInstructions ? true : false}
                helperText={formErrors.fillInstructions ? formErrors.fillInstructions : ''}
                onChange={(event) => { handleSetInputs('fillInstructions', event.target.value) }}
                required={inputs.rxItem === 'true' ? true : false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <h4>UOM and Dimensions</h4>
            <FormControl className={classes.formControlSmall}>
              <TextField
                select
                variant="outlined"
                label={'UOM'}
                value={selectedDimension.uom || ''}
                // error={formErrors.uom ? true : false}
                // helperText={formErrors.uom || ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, uom: event.target.value }) }}
                required
              >
                <MenuItem value={'Each'}>Each</MenuItem>
                <MenuItem value={'Applicator'}>Applicator</MenuItem>
                <MenuItem value={'Box'}>Box</MenuItem>
              </TextField>
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Qty"
                variant="outlined"
                type="number"
                value={selectedDimension.uomQuantity || ''}
                // error={formErrors.width ? true : false}
                // helperText={formErrors.width ? formErrors.width : ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, uomQuantity: event.target.value }) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Width mm"
                variant="outlined"
                type="number"
                value={selectedDimension.width || ''}
                // error={formErrors.width ? true : false}
                // helperText={formErrors.width ? formErrors.width : ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, width: event.target.value }) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Height mm"
                variant="outlined"
                type="number"
                value={selectedDimension.height || ''}
                // error={formErrors.height ? true : false}
                // helperText={formErrors.height ? formErrors.height : ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, height: event.target.value }) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Length mm"
                variant="outlined"
                type="number"
                value={selectedDimension.length || ''}
                // error={formErrors.length ? true : false}
                // helperText={formErrors.length ? formErrors.length : ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, length: event.target.value }) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <TextField
                label="Weight g"
                variant="outlined"
                type="number"
                value={selectedDimension.weight || ''}
                // error={formErrors.weight ? true : false}
                // helperText={formErrors.weight ? formErrors.weight : ''}
                onChange={(event) => { setSelectedDimension({ ...selectedDimension, weight: event.target.value }) }}
                required
              />
            </FormControl>
            <FormControl className={classes.formControlSmall}>
              <Button
                onClick={() => {
                  let tempDimensions = dimensions
                  tempDimensions.push(selectedDimension)
                  setDimensions(tempDimensions)
                  setSelectedDimension({})
                }}
                type="button"
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                className={classes.viewAllBtn}
                disabled={!selectedDimension.uom || !selectedDimension.uomQuantity || !selectedDimension.width || !selectedDimension.height || !selectedDimension.length || !selectedDimension.weight}
              >
                ADD
              </Button>
            </FormControl>
          </Grid>
          {formErrors.packingItems &&
            <Grid item xs={12}>
              <span className={classes.errorMsg}>{formErrors.packingItems}</span>
            </Grid>
          }
          <Grid>
            {dimensions.map((dimension: any, pIndex: number) => (
              <Paper key={pIndex} elevation={0} variant={'outlined'} style={{ display: 'flex' }}>
                {delete dimension.productId && Object.keys(dimension).map((chip: any, cIndex: number) => (
                  <Chip
                    key={cIndex}
                    variant="outlined"
                    style={{ margin: 3 }}
                    label={`${chip.toUpperCase()}: ${dimension[chip]}`}
                    size="small"
                  />
                ))}
                <Tooltip title="Edit" placement="bottom-start">
                  <EditIcon
                    onClick={(e) => {
                      if (Object.keys(selectedDimension).length > 0) return
                      initEditUom(dimensions[pIndex])
                      removeUom(pIndex)
                    }}
                    style={{ marginRight: 10, cursor: 'pointer' }}
                  />
                </Tooltip>
                <Tooltip title="Delete" placement="bottom-start">
                  <DeleteForever
                    onClick={(e) => {
                      removeUom(pIndex)
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              </Paper>
            ))}
          </Grid>
          {submitError && <p className={classes.errorMsg}>{submitError}</p>}
          {isLoading ?
            <Loading message="" />
            :
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleSave}
                  type="button"
                  style={{ marginTop: 12 }}
                  className={classes.searchButton}
                  variant="contained"
                  color="primary"
                >
                  SAVE
                </Button>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Button
                  onClick={handleCancel}
                  type="button"
                  style={{ marginTop: 12, marginLeft: 0 }}
                  className={classes.cancelBtn}
                  variant="contained"
                  color="default"
                >
                  CANCEL
                </Button>
              </FormControl>
            </Grid>}
        </Grid>
      </div>
    </div >
  )
}

const mapStateToProps = (state: any) => ({
  serviceTypes: state.ui.serviceTypes ? state.ui.serviceTypes : [],
  addressTypes: state.ui.addressTypes ? state.ui.addressTypes : [],
});

const mapDispatchToProps = (dispatch: any) => ({
  createProduct: (payload: any) => dispatch(ProductActions.createProduct(payload)),
  updateProduct: (payload: any) => dispatch(ProductActions.updateProduct(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFormModal);
