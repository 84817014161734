import * as React from 'react';
import { FormControl, Button, Modal, Tooltip, Grid, Paper } from '@material-ui/core';
import { CommonStyles } from '../../hooks/styles';
import dataList from '../../constants/dataList';
import MUIDataTable from "mui-datatables";
import Loading from '../../components/loading';
import ProductFormModal from './product-form-modal.component';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever } from '@material-ui/icons';
import DeleteModal from '../../components/deleteModal';
import SearchFilter from '../../components/searchFilter';
import { Chip } from '@mui/material';

interface ProductsProps {
	authUser?: any
	products: any[]
	totalProducts: any
	globalRowsPerPage: any
	getProducts: (filter: any) => void
	deleteProduct: (payload: any) => void
	setGlobalRowsPerPage: (numRows: any) => void
}

export const ProductsComponent: React.FC<ProductsProps> = (props) => {
	const classes = CommonStyles();
	const firstRender = React.useRef(false);
	const [modalOpen, setModalOpen] = React.useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
	const [deleteId, setDeleteId] = React.useState(null as any)
	const [productToEdit, setProductToEdit] = React.useState(null as any)
	const [filters, setFilters] = React.useState<any>({})
	const [sortOrder, setSortOrder] = React.useState(null as any)
	const [tableStatePersist, setTableStatePersist] = React.useState(null as any);
  	const [expandedPackSetRows, setExpandedPackSetRows] = React.useState<any>({})
  	const [pagination, setPageState] = React.useState({
		offset: 0,
		limit: props.globalRowsPerPage,
		page: 0
	});

	const renderIcons: any = (tableMeta: any) => {
		return (
			<div style={{ color: 'rgba(0, 0, 0, 0.54)', float: 'right' }}>
				<Tooltip title="Edit" placement="bottom-start">
					<EditIcon
						onClick={(e) => {
							triggerModal(props.products[tableMeta.rowIndex]);
						}}
						style={{ marginRight: 10, cursor: 'pointer' }}
					/>
				</Tooltip>
				<Tooltip title="Delete" placement="bottom-start">
					<DeleteForever
						onClick={(e) => {
							triggerDeleteModal(props.products[tableMeta.rowIndex].id)
						}}
						style={{ cursor: 'pointer' }}
					/>
				</Tooltip>
			</div>
		)
	}

	const renderDimensions: any = (value: any, tableMeta: any) => {
		const dimensions = JSON.parse(value).map((el: any) => [`UOM: ${el.uom}`, `QTY: ${el.uomQuantity}`, `Height: ${el.height}mm`, `Length: ${el.length}mm`, `Width: ${el.width}mm`])
		let hiddenDimensionsCount = 0
		let hiddenDimensions: any[] = []
		if (dimensions.length > 3) {
			hiddenDimensionsCount = dimensions.length - 3
			hiddenDimensions = dimensions.splice(2, hiddenDimensionsCount)
		}
		return (
			<>
				{dimensions.map((dimension: any, index: number) => (
					<Paper key={index} elevation={0} variant={'outlined'} style={{display: 'flex'}}>
						{dimension.map((chip: any, index: number) => (
							<Chip
								key={index}
								variant="outlined"
								style={{ margin: 3 }}
								label={chip}
								size="small"
							/>
						))}
					</Paper>
				))}
				{expandedPackSetRows[tableMeta.rowIndex] ?
				<>
					{hiddenDimensions.map((dimension: any, index: number) => (
						<Paper elevation={0} variant={'outlined'} style={{display: 'flex'}}>
							{dimension.map((chip: any, index: number) => (
								<Chip
									key={index}
									variant="outlined"
									style={{ margin: 3 }}
									label={chip}
									size="small"
								/>
							))}
						</Paper>
					))}
					<span onClick={() => { setExpandedPackSetRows({...expandedPackSetRows, [tableMeta.rowIndex]: false})}} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Show Less</span>
				</>
				:
				<span onClick={() => { setExpandedPackSetRows({...expandedPackSetRows, [tableMeta.rowIndex]: true})}} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{hiddenDimensionsCount > 0 ? `+${hiddenDimensionsCount}` : ``}</span>
				}
			</>
		)
	}

	const columnData = [
		{
			label: "ID",
			name: "id",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Name",
			name: "name",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Pack Sets",
			name: "packSets",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => {
				const packSets = JSON.parse(value).map((packSet: any) => packSet.id)
				let hiddenPackSetCount = 0
				let hiddenPackSets: any[] = []
				if (packSets.length > 3) {
					hiddenPackSetCount = packSets.length - 3
					hiddenPackSets = packSets.splice(2, hiddenPackSetCount)
				}
				return (
					<>
						{packSets.map((message: any, index: number) => (
						<Chip
							key={index}
							variant="outlined"
							style={{ margin: 3 }}
							label={message}
							size="small"
						/>
						))}
						{expandedPackSetRows[tableMeta.rowIndex] ?
						<>
							{hiddenPackSets.map((message: any, index: number) => (
							<Chip
								key={index}
								variant="outlined"
								style={{ margin: 3 }}
								label={message}
								size="small"
							/>
							))}
							<span onClick={() => { setExpandedPackSetRows({...expandedPackSetRows, [tableMeta.rowIndex]: false})}} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Show Less</span>
						</>
						:
						<span onClick={() => { setExpandedPackSetRows({...expandedPackSetRows, [tableMeta.rowIndex]: true})}} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{hiddenPackSetCount > 0 ? `+${hiddenPackSetCount}` : ``}</span>
						}
					</>
				)
			}
		},
		{
			label: "Manufacturer",
			name: "manufacturer",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Description",
			name: "description",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "NDC",
			name: "ndc",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Dimensions",
			name: "productDimensions",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => { return renderDimensions(value, tableMeta) }
		},
		{
			label: "Serialized",
			name: "serialized",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value ? 'Yes' : 'No'
			}
		},
		{
			label: "RX Item",
			name: "rxItem",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value ? 'Yes' : 'No'
			}
		},
		{
			label: "Med Guide",
			name: "medguide",
			change: true,
			viewColumns: true,
			display: true,
			customBodyRender: (value: any) => {
				return value ? 'Yes' : 'No'
			}
		},
		{
			label: "Bag Label",
			name: "rxItemBagLabel",
			change: true,
			viewColumns: true,
			display: true,
			sort: false,
			customBodyRender: (value: any) => {
				return value ? 'Yes' : 'No'
			}
		},
		{
			label: "Bag Max",
			name: "rxBagMax",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Pack Max",
			name: "rxPackMax",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Fill Instructions",
			name: "fillInstructions",
			change: true,
			viewColumns: true,
			display: true
		},
		{
			label: "Default Carrier",
			name: "defaultCarrier",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Default Service",
			name: "defaultServiceType",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Min Temp",
			name: "minTemp",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Max Temp",
			name: "maxTemp",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Storage",
			name: "storage",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "Address Type",
			name: "addressType",
			change: true,
			viewColumns: true,
			display: false
		},
		{
			label: "",
			name: "",
			change: false,
			viewColumns: false,
			display: true,
			sort: false,
			customBodyRender: (value: any, tableMeta: any) => { return renderIcons(tableMeta) }
		},
	]

	const getTableColumns = () => {
		return columnData.map((column: any, index: number) => {
			return {
				label: column.label,
				name: column.name,
				options: {
					filter: true,
					display: tableStatePersist ? tableStatePersist.columns[index].display : column.display,
					change: column.change,
					sort: column.sort ?? true,
					viewColumns: column.viewColumns,
					...(column.customBodyRender) && { customBodyRender: column.customBodyRender },
				}
			}
		})
	}

	React.useEffect(() => {
		if (props.authUser && !firstRender.current) {
			filterProducts()
		}
		else {
			firstRender.current = false;
		}
	}, [pagination, sortOrder])

	const getFilter = (reset?: boolean) => {
		const filter: any = {
			limit: props.globalRowsPerPage,
			offset: pagination.offset,
			order: sortOrder ? [sortOrder] : ['id'],
			where: {active: true}, //filters ultimately end up here 
			include: [
				{relation: 'productDimensions'},
				{
					relation: 'packSets',
					scope: {
						where: {active: true}
					}
				}
			]
		}

		Object.keys(filters).forEach((filterKey: string) => {
			if (filterKey === 'description') {
				filter.where[filterKey] = filters[filterKey] ? { ilike: `%${filters[filterKey]}%` } : undefined
				return
			}
			filter.where[filterKey] = filters[filterKey] || undefined
		})

		return filter
	}

	const filterProducts = (reset?: boolean) => {
		props.getProducts({ filter: getFilter(reset) })
	}

	const handleSearch = () => {
		setPageState({
			offset: 0,
			limit: props.globalRowsPerPage,
			page: 0
		})
	}

	const showLoadingMsg = () => (
		!props.authUser && props.products.length === 0
	)

	const handleModalClose = () => {
		setModalOpen(false);
		setProductToEdit(null)
	};

	const handleDeleteModalClose = () => {
		setDeleteModalOpen(false);
		setDeleteId(null)
	};

	const triggerModal = (product: any) => {
		setProductToEdit(product);
		setModalOpen(true);
	}

	const triggerDeleteModal = (id: any) => {
		setDeleteId(id);
		setDeleteModalOpen(true);
	}

	return (<div>
		{showLoadingMsg() ? <div><Loading message="" /></div> : <div>
			<div className={classes.searchWrap}>
				<Grid container>
					<Grid item xs={'auto'}>
						<SearchFilter
							config={[
								{
									label: 'Product Description',
									name: 'description',
									type: 'text'
								}
							]}
							onFilterChange={setFilters}
							filters={filters}
							handleSearch={() => setPageState({...pagination, offset: 0, page: 0})}
						/>
					</Grid>
				</Grid>
			</div>

			<div className={classes.pageActionsWrap}>
				<FormControl className={classes.formControlSmall}>
					<Button
						className={classes.viewAllBtn}
						variant="contained"
						color="secondary"
						onClick={() => {
							triggerModal(null)
						}}
					> Add Product </Button>
				</FormControl>
			</div>
			<div>
				<MUIDataTable
					data={props.products}
					columns={getTableColumns()}
					options={{
						rowsPerPage: props.globalRowsPerPage,
						rowsPerPageOptions: dataList.PageLimitOptions,
						onChangeRowsPerPage: (numberOfRows: number) => {
							setPageState({ offset: 0, limit: numberOfRows, page: 0 });
							props.setGlobalRowsPerPage(numberOfRows);
						},
						filterType: "dropdown",
						responsive: "simple",
						filter: false,
						search: false,
						download: true,
						print: true,
						selectableRows: 'none',
						serverSide: true,
						page: pagination.page,
						count: props.totalProducts,
						onColumnSortChange: (changedColumn: string, direction: string) => {
							setSortOrder(`${changedColumn} ${direction.toUpperCase()}`)
						},
						onDownload: (buildHead: any, buildBody: any, columns: any) => {
							const filter = getFilter()
							delete filter.limit
							delete filter.offset
							const exportOptions = {
								columns: columns,
								timestampFormat: 'YYYY-MM-DD hh:ss a',
								fileName: 'products'
							}
							props.getProducts({ filter: filter, exportOptions: exportOptions })
							return false
						},
						onTableChange: (tableAction: any, tableState: any) => {
							switch (tableAction) {
								case 'changePage':
									if (tableState.page > pagination.page) {
										setPageState({ offset: pagination.offset + props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									else if (tableState.page < pagination.page) {
										setPageState({ offset: pagination.offset - props.globalRowsPerPage, limit: props.globalRowsPerPage, page: tableState.page });
									}
									break;
								case 'viewColumnsChange':
									setTableStatePersist(tableState);
									break;
							}
						},
					}}
				/>
			</div>
			{/* :
				<EmptyContent message="Change the filter criteria above to view product information." />
			} */}
			<Modal
				open={modalOpen}
				onClose={(e: any, reason: string) => {
					if (reason === 'backdropClick') return
					handleModalClose()
				}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<ProductFormModal getProducts={filterProducts} product={productToEdit} closeModal={handleModalClose} />
				</div>
			</Modal>
			<Modal
				open={deleteModalOpen}
				onClose={handleDeleteModalClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				<div>
					<DeleteModal
						deleteId={deleteId}
						title={'Delete Product'}
						message={`Are you sure you want to delete the product with ID ${deleteId}`}
						onDelete={props.deleteProduct}
						onSuccess={handleSearch}
						closeModal={handleDeleteModalClose} />
				</div>
			</Modal>
		</div>}
	</div>)
}